import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Typography, Spin, Form, Input, Checkbox, Button, notification, Layout } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import sha512 from 'js-sha512';

import moment from 'moment';
import 'moment/min/locales';

import Core from '../../Core';
import RecoverModal from './RecoverModal/index.jsx';
import styles from "./index.scss";
import Countdown from "../../components/FlipCountDown/Countdown.js";

const { Title } = Typography;
moment.locale('pt');

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            counter: 0,
            loginOutput: null,
            error: false,
            visibleModal: false
        };
        this.login = this.login.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (this.props.token) {
            this.login(this.props.token);
        }

        this.loadHome();
    }

    loadHome() {
        Core.apiData(`pages/home.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    console.log("TESTE")
                    console.log(data.json);
                    this.setState({
                        data: data.json,
                    });
                } else {
                    this.setState({ error: true });
                    console.log("Schedule data is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true });
                console.log("Schedule data failed.", data);
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleClick(event) {
        this.setState({
            visibleModal: !this.state.visibleModal
        });
    }

    login(hash, values) {
        this.setState({ loading: true });
        const fail = () => {
            this.setState({ loading: false });
            notification.error({
                message: 'Login',
                description: <div>Access data is invalid.</div>,
                top: 200
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Login`,
                label: `Unsuccessful`
            });
        };
        Core.apiData(`login/${hash}.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    Core.data().hash = hash;
                    Core.data().arena = data.json.arena;
                    Core.data().login = data.json;
                    sessionStorage.setItem("data", JSON.stringify(Core.data()));
                    const { onLoggedIn } = this.props;
                    if (onLoggedIn) {
                        onLoggedIn(Core.data());
                    }
                    this.setState({ loading: false });

                    ReactGA.event({
                        category: Core.data().config.site_title,
                        action: `Login`,
                        label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
                    });

                } else {
                    //this.setState({ error: true });
                    //console.log("Login data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                //this.setState({ error: true });
                //console.log("Login data failed.", data);
                if (Core.data().config.email_only) {
                    Core.apiService(`register`, {
                        data: {
                            mail: values.mail.toLowerCase()
                        },
                        onSuccess: (data) => {
                            if (data.json) {
                                Core.apiData(`login/${hash}.json`, {
                                    onSuccess: (data) => {
                                        if (data.json) {
                                            Core.data().hash = hash;
                                            Core.data().arena = data.json.arena;
                                            Core.data().login = data.json;
                                            sessionStorage.setItem("data", JSON.stringify(Core.data()));
                                            const { onLoggedIn } = this.props;
                                            if (onLoggedIn) {
                                                onLoggedIn(Core.data());
                                            }
                                        } else {
                                            fail();
                                        }
                                    }
                                });
                            } else {
                                console.log("Register data is not a JSON!");
                                fail();
                            }
                        },
                        onFail: (data) => {
                            console.log("Register data failed.", data);
                            fail();
                        }
                    });
                } else {
                    fail();
                }
            }
        });
    }

    onFinish(values) {
        const hash = sha512(Core.data().config.no_password || Core.data().config.email_only ? values.mail.toLowerCase() : values.mail.toLowerCase() + ':' + values.password);
        if (values.remember) {
            sessionStorage.setItem('mail', values.mail.toLowerCase());
            sessionStorage.setItem('password', values.password);
        }
        this.login(hash, values);
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    render() {
        const { loading, counter, visibleModal } = this.state;
        if (loading) {
            return (<div className="login"><Spin /></div>);
        }
        const layout = {
            // labelCol: { xl: { span: 10 }, lg: { span: 12 }, md: { span: 14 }, sm: { span: 16 } },
            // wrapperCol: { xl: { span: 10 }, lg: { span: 12 }, md: { span: 14 }, sm: { span: 16 } },
        };
        const tailLayout = {
            // wrapperCol: {
            //     xl: { span: 10, offset: 7 }, lg: { offset: 6, span: 12 }, md: { span: 14, offset: 5 }, sm: { offset: 4, span: 16 }
            // },
        };

        let startDate = null;
        if (this.state.data && this.state.data.startDate) {
            startDate = moment(this.state.data.startDate, 'YYYY-MM-DD HH:mm:ss');
        }

        return (
            <div className="main">
                <Layout>
                    <div className="main--wrapper">
                        <div className="logo">
                            <Link to="/">
                                <img src="/data/images/logo.png" />
                            </Link>
                        </div>
                        <div className="main--firts-section">
                            <div className="main--title">
                                <h1 className="main--title-h1"><b>{this.state.data ? this.state.data.title : null}</b></h1>
                                <p className="main--title-secondary"><b>Digital Press Event</b></p>
                                <p className="main--title-p"><b>{this.state.data ? this.state.data.content : null}</b></p>
                                <p className="main--title-p"><b>Friday, March 10<span id="super">th</span> 2023</b></p>
                                <p className="main--title-p"><b>Livestream at 09:00 AM GMT and 04:00 PM GMT</b></p>
                            </div>
                        </div>
                        <div className={loading ? "login" : "login login-background"}>
                            <Form
                                {...layout}
                                name="basic"
                                initialValues={{
                                    mail: sessionStorage.getItem("mail"),
                                    password: sessionStorage.getItem("password"),
                                    remember: true
                                }}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Form.Item
                                    className="center-input"
                                    name="mail"
                                    rules={[{ required: true, type: 'email', message: '' }]}
                                >
                                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="E-mail" />
                                </Form.Item>
                                <Form.Item
                                    className="center-input"
                                    name="password"
                                    rules={[{ required: (!Core.data().config.no_password) && (!Core.data().config.email_only), message: '' }]}
                                    style={{ display: Core.data().config.no_password || Core.data().config.email_only ? 'none' : 'block' }}
                                >
                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                                </Form.Item>
                                <Form.Item
                                    className="center-input checkbox-terms"
                                    name="accept-terms"
                                    valuePropName="checked"
                                    rules={[{ required: true, message: 'You need to accept terms and conditions!' }]}
                                >
                                    <Checkbox>I accept the terms and conditions</Checkbox>
                                </Form.Item>

                                <Form.Item {...tailLayout} className="login__form-action">
                                    <Button className="submit-button" type="primary" htmlType="submit">
                                        Login
                                    </Button>
                                </Form.Item>

                                <Form.Item {...tailLayout}
                                    className="register__form-action"
                                    style={{ display: Core.data().config.register ? 'block' : 'none' }}>
                                    <hr></hr>
                                    <Button className="register-button" type="primary" htmlType="submit">
                                        <a href="./registo">Registar</a>
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="main--countdown">
                            {this.state.data ?
                                <Countdown
                                    date={startDate.format('YYYY/MM/DD HH:mm:ss')}
                                    useFlipView={true}
                                    expiryMsg={""}
                                /> : null}
                        </div>

                    </div>
                </Layout>
            </div>
        );
    }
}
