import React, { Component } from "react";
import {Layout, Typography} from 'antd';
import Linkify from 'react-linkify';
import { getSocket, initSocket } from '../../socket';

import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;

export default class QuestionsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queue: []
        };
    }

    componentDidMount() {
        this.loadData();

        if (Core.data().login && getSocket() == null) {
            initSocket(Core.data().login);
        }

        console.log(getSocket())
        if (getSocket() !== null) {
            const that = this;
            getSocket().on('refreshQuestionBox', function () {
                that.loadData();
            });
        }

        this.interval = setInterval(() => this.loadData(), 5000);
    }
    
    loadData() {
        Core.apiData(`question-box/PU87hnF4CGFzm73e.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    let hasChange = false;
                    for (const item of data.json) {
                        let found = false;
                        for (const question of this.state.queue) {
                            if (item.uid == question.uid) {
                                found = true;
                                break;
                            }
                        }
                        if (found == false) {
                            hasChange = true;
                            break;
                        }
                    }
                    for (const question of this.state.queue) {
                        let found = false;
                        for (const item of data.json) {
                            if (item.uid == question.uid) {
                                found = true;
                                break;
                            }
                        }
                        if (found == false) {
                            hasChange = true;
                            break;
                        }
                    }
                    if (hasChange) {
                        this.setState({ queue: data.json });
                    }
                } else {
                    console.log("Question Box - Fail to fetch, data is not a json");
                }
            },
            onFail: (data) => {
                console.log("Question Box - Queue", data);
            }
        });
    }

    render() {
        const divs = [];
        const { queue } = this.state;
        for (const question of queue) {
            if (question.question) {
                divs.push(
                    <div>
                        <div className="question-box-queue__listitem">
                            <div className="genericPfp"></div>
                            <p class="participant-name">{question.participant_name}</p>
                            <p className="question-box-queue__paragraph">
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="_blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                                >
                                    {question.question}
                                </Linkify>
                            </p>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="questions-page">
                <div ref={this.container} className="question-box-queue">
                    { divs}
                </div>
            </div>
        );
    }
}
