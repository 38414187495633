import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';

import QuestionBox from './QuestionBox';
import ChatBox from './ChatBox';
import LivePoll from './LivePoll';
import Core from '../../Core';

//import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import ResponsiveEmbed from 'react-responsive-embed';
import { getSocket, initSocket } from '../../socket';

import styles from "./index.scss";

export default class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            update: 0,
            arenaLoaded: false,
            window: {
                width: 0,
                height: 0
            },
            splashScreen: false
        };
        this.fullscreen = false;
        this.checkSessionStarted = false;
        this.updateWindowDimensionsTimeout = null;
        this.loadArena = this.loadArena.bind(this);
        this.checkSplashScreen = this.checkSplashScreen.bind(this);
        this.message = React.createRef();
        this.content = React.createRef();
        this.extra = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateFullscreen = this.updateFullscreen.bind(this);
    }

    componentDidMount() {
        this.loadArena();
	console.log("HOLA");
        const that = this;
        if (Core.data().login && getSocket() == null) {
            initSocket(Core.data().login);

            getSocket().on('refreshArena', function () {
                that.loadArena();
            });
        }

        this.updateWindowDimensions();
        this.checkSplashScreen();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('fullscreenchange', this.updateFullscreen);
        window.addEventListener('msfullscreenchange', this.updateFullscreen);
        window.addEventListener('mozfullscreenchange', this.updateFullscreen);
        window.addEventListener('webkitfullscreenchange', this.updateFullscreen);

        ReactGA.pageview(window.location.pathname);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('fullscreenchange', this.updateFullscreen);
        window.removeEventListener('msfullscreenchange', this.updateFullscreen);
        window.removeEventListener('mozfullscreenchange', this.updateFullscreen);
        window.removeEventListener('webkitfullscreenchange', this.updateFullscreen);
    }

    updateWindowDimensions() {
        if (this.updateWindowDimensionsTimeout) {
            window.clearTimeout(this.updateWindowDimensionsTimeout);
        }
        this.updateWindowDimensionsTimeout = window.setTimeout(() => {
            if (this.fullscreen) {
                return;
            }
            if (this.content.current != null) {
                const maxWidth = this.content.current.offsetWidth;
                if (this.content.current.innerHTML == '') {
                    this.content.current.innerHTML = Core.data().login.content;
                }
                const iframe = this.content.current.querySelector("iframe");
                if (iframe != null) {
                    /*const height = iframe.offsetHeight;
                    const width = height * 1920 / 1080;
                    if (width <= maxWidth) {
                        iframe.style.maxWidth = width +'px';
                        iframe.style.width = width +'px';
                    } else {
                        iframe.style.maxWidth = maxWidth +'px';
                        iframe.style.width = maxWidth +'px';
                    }*/
                    const width = maxWidth;
                    const height = width * 1080 / 1920;
                    iframe.style.width = width + 'px';
                    iframe.style.height = height + 'px';
                }
            }
        }, 500);
    }

    updateFullscreen() {
        this.fullscreen = !this.fullscreen;
    }

    loadArena() {
        Core.apiData(`${Core.data().arena}.json`, {
            onSuccess: (data) => {
                const { message, content, extra, sessions, realtime, qa_disabled, chat_disabled } = Core.data();
                Core.data().sessions = data.json.sessions;
                const { update, arenaLoaded } = this.state;
                if (data.json.message != message
                    || data.json.content != content
                    || data.json.extra != extra
                    || data.json.sessions != sessions
                    || data.json.realtime != realtime
                    || data.json.qa_disabled != qa_disabled
                    || data.json.chat_disabled != chat_disabled
                ) {
                    Core.data().message = data.json.message;
                    Core.data().content = data.json.content;
                    Core.data().extra = data.json.extra;
                    Core.data().realtime = data.json.realtime;
                    Core.data().qa_disabled = data.json.qa_disabled;
                    Core.data().chat_disabled = data.json.chat_disabled;
                    sessionStorage.setItem("data", JSON.stringify(Core.data()));
                    this.setState({ update: update + 1, arenaLoaded: true });
                } else if (arenaLoaded == false) {
                    this.setState({ update: update + 1, arenaLoaded: true });
                }
            },
            onFail: (data) => {
                console.log("Load Arena", data);
            }
        });
    }

    checkSplashScreen() {
        const that = this;

        fetch(Core.configAPIPath() + 'images/splash-screen?refresh=1')
            .then(function (response) {
                if (response.status == 500) {
                    that.setState({ splashScreen: false });
                } else {
                    that.setState({ splashScreen: true });
                }
            })
            .catch(function (error) {
                that.setState({ splashScreen: false });
            });
    }

    render() {
        console.log("splashScreen");
        console.log(this.state.splashScreen);
        if (Core.data().login == null) {
            return <Redirect to="/login" />;
        }

        if (this.state.arenaLoaded == false) {
            return <Spin />;
        }
        const data = Core.data();
        const realtime = Core.data().realtime
        const spashScreen = realtime ? realtime.splash_screen : false;
        let message = '';
        if (Core.data().message != '') {
            message = Core.data().message
                .replace("%name%", Core.data().login.name)
                .replace("%secret%", Core.data().login.secret);
        }
        return (
            <div className="player">
                <div className="player__main">
                    <div className="logo">
                        <Link to="/">
                            <img src="/data/images/logo.png" />
                        </Link>
                    </div>
                    <p className="player__title-custom">Swatch Art Journey Digital Press Event</p>
                    <div id="main-wrapper">
                        <div id="main-column-wrapper">
                            {Core.data().content != '' ?
                                <>
                                    <div className="player__content only-video">
                                        <div className="embed-container" ref={this.content} dangerouslySetInnerHTML={{ __html: (this.state.splashScreen && spashScreen ? "<div class='splash-name-img'></div>" : Core.data().content) }}></div>
                                    </div>
                                </>
                                : null}
                        </div>
                    </div>
                    <p class="player__title-custom player__title-custom-after">Don't forget to press play to start the livestream</p>
                    {Core.data().config.chat == true || Core.data().config.questions == true ?
                        <div id="main-column">
                            {Core.data().config.questions == true ?
                                <div className="player__question-box">
                                    {Core.data().config.questions == true ? <QuestionBox data={data} /> : null}
                                </div>
                                : null}
                            {Core.data().config.chat == true ?
                                <div className={Core.data().config.questions == true ? "player__chat" : "player__chat only-chat"}>
                                    {Core.data().config.chat == true ? <ChatBox data={data} /> : null}
                                </div>
                                : null}
                            <div className="player__qr-code">
                                <p>
                                    Click <a href="https://contentserv.swatch.com/admin/share/adfad46c" target="_blank">here</a> or scan the QR code to download the <strong>press kit</strong>
                                </p>
                                <img src="/images/qr-code.png" alt="QR Code" />
                            </div>
                        </div>
                        : null}
                    

                    <div class="hashtags">
                        <p><a href="https://instagram.com/swatch?igshid=YmMyMTA2M2Y=" target="_blank">@Swatch</a></p>
                        <p>#SwatchArtJourney</p>
                    </div>


                    {Core.data().config.live_poll ?
                        <LivePoll data={data} />
                        : null
                    }
                    {Core.data().extra != '' ?
                        <Row gutter={20} className="player__container__extra">
                            <Col xs={24}>

                                <div className="player__extra">
                                    <div ref={this.extra} dangerouslySetInnerHTML={{ __html: Core.data().extra }}></div>
                                </div>
                            </Col>
                        </Row>
                        : null}
                </div>
            </div>
        );
    }
}
