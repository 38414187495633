import React, { Component } from "react";

import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import notification from 'antd/lib/notification';
import ReactGA from 'react-ga';

import { LockOutlined } from "@ant-design/icons";
import { getSocket } from '../../../socket';

import Queue from './Queue';
import Core from '../../../Core';
import styles from "./index.scss";

export default class QuestionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.form = React.createRef();
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidMount() {
        const that = this;
        getSocket().on('newQuestionAck', (msg) => {
            console.log(msg);
            that.handleNewQuestionAck(msg);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleFinish(values) {
        this.setState({ loading: true });
        const fail = () => {
            this.setState({ loading: false });
            notification["error"]({
                message: 'Question',
                description: 'We were unable to submit your question, please try again later.',
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Q&A`,
                label: `Unsuccessful`
            });
        };
        if (getSocket() != null) {
            getSocket().emit("newQuestion", {
                participant_uid: this.props.data.login.uid,
                question: values.question
            });
            this.form.current.resetFields();
            this.setState({ voteLoading: false, loading: false });
        }
        // Core.apiService("question-box/create", {
        //     data: {
        //         participant_uid: this.props.data.login.uid,
        //         question: values.question
        //     },
        //     onSuccess: (data) => {
        //         if (data.json && data.json.result) {
        //             notification.success({
        //                 message: 'Questão',
        //                 description: 'A tua questão foi submetida com sucesso.',
        //                 top: 200
        //             });
        //             this.form.current.resetFields();
        //             this.setState({ loading: false });
        //             ReactGA.event({
        //                 category: Core.data().config.site_title,
        //                 action: `Q&A`,
        //                 label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
        //             });
        //         } else {
        //             console.log("Response of alert submit is not JSON!");
        //             fail();
        //         }
        //     },
        //     onFail: (data) => {
        //         console.log("Quesetion create failed.", data);
        //         fail();
        //     }
        // });
    }

    handleNewQuestionAck(msg) {
        if (msg && msg.result == true) {
            notification.success({
                message: 'Question',
                description: 'Your question has been successfully submitted.',
                top: 200
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Q&A`,
                label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
            });
        } else {
            console.log("Response of alert submit is not JSON!");
        }
    }

    render() {
        const { loading } = this.state;
        return (
            <div className="question-box">
                <div className="question-box-queue-container">
                    <h3>Q&A with Alain Villard (CEO, SWATCH) and Carlo Giordanetti (CEO, SWATCH ART PEACE HOTEL)</h3>
                    {Core.data().qa_disabled ?
                        <Row align="middle" className="blocked-input">
                            <Col span={4}>
                                <LockOutlined />
                            </Col>
                            <Col span={20}>
                                <span>We are currently not accepting new questions</span>
                            </Col>
                        </Row>
                        :
                        <Form className="newQuestion" ref={this.form} layout="vertical" onFinish={this.handleFinish}>
                            <Row gutter={{xl: 12, md: 12, xs: 12}} align="bottom">
                                <Col xl={20} md={20} xs={24}>
                                <div className="question-box__title">
                                </div>
                                    < Form.Item className="alertQuestion" name="question" rules={[
                                        {
                                            required: true,
                                            message: 'Please fill in your question.'
                                        }
                                    ]}>
                                        <Input.TextArea className="inputQuestion" placeholder="Submit your question(s) here" autoSize />
                                    </Form.Item>
                                </Col>
                                <Col xl={4} md={4} xs={24}>
                                    <Spin spinning={loading}>
                                        <Button className="buttonSend" id="questionSend" type="primary" htmlType="submit" loading={loading}>
                                            Send
                                </Button>
                                    </Spin>
                                </Col>
                            </Row>
                        </Form>
                    }
                </div>
            </div >
        );
    }
}
