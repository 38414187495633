import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Layout, Typography, Button, Row, Col, Card, Spin } from 'antd';

import moment from 'moment';
import 'moment/min/locales';

import Core from "../../Core.js";

import Speakers from '../../containers/Speakers';
import ScheduleContainer from '../../containers/Schedule/';
import Countdown from "../../components/FlipCountDown/Countdown.js";
import { CalendarOutlined } from '@ant-design/icons';

import './index.scss';

const { Title } = Typography;
moment.locale('pt');

export default class MainPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            redirectPlayer: false,
            data: []
        };
    }

    componentDidMount() {
        if (Core.data().config.no_home){
          this.setState({ redirectPlayer: true });
        }
        if (Core.data().config.private == true && Core.data().login == null) {
            this.setState({ redirectLogin: true });
        } else {
            this.loadHome();
        }
    }

    setRedirect = () => {
        this.setState({
            redirectPlayer: true
        });
    }

    loadHome() {
        Core.apiData(`pages/home.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        data: data.json,
                    });
                } else {
                    this.setState({ error: true });
                    console.log("Schedule data is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true });
                console.log("Schedule data failed.", data);
            }
        });
    }

    render() {
        const { redirectLogin, redirectPlayer, redirect } = this.state;
        const isMobile = window.innerWidth <= 500;
        let hasCountDown = false;
        let eventRange = "";
        let startDate = null;
        if (this.state.data.startDate && this.state.data.endDate) {
          startDate = moment(this.state.data.startDate, 'YYYY-MM-DD HH:mm:ss');
          const endDate = moment(this.state.data.endDate, 'YYYY-MM-DD HH:mm:ss');

          const capitalizeStartMonth = startDate ? startDate.locale("en").format("MMMM").charAt(0).toUpperCase() + startDate.locale("en").format("MMMM").slice(1) : null;
          const capitalizeEndMonth = endDate.locale("en").format("MMMM").charAt(0).toUpperCase() + endDate.locale("en").format("MMMM").slice(1);

            if (endDate.format('D') == startDate.format('D')
                && endDate.format('M') == startDate.format('M')
                && endDate.format('YYYY') == startDate.format('YYYY'))  {
                eventRange =  `${capitalizeEndMonth} ${startDate.format('D')} ${endDate.format('Y')}`;
            } else if(endDate.format('M') == startDate.format('M')){
                eventRange =  `${capitalizeEndMonth} ${startDate.format('D')} to ${endDate.format('D')} ${endDate.format('Y')}`;
            } else {
                eventRange =  `${capitalizeStartMonth} ${startDate.format('D')} to ${capitalizeEndMonth} ${endDate.format('d')} ${endDate.format('Y')}`;
            }
        }
        if (redirectLogin) {
          window.location.assign("/login");
        }
        if (redirectPlayer) {
            if(this.state.data.eventLink == '' || this.state.data.eventLink == null){
                window.location.assign("/player")
            } else {
                return window.location.assign(this.state.data.eventLink);
            }
        }
        if (redirect) {
          window.location.assign("/registo");
        }

        return (
            <div className="main">
                <Layout>
                  <div className="main--wrapper">
                    {isMobile ? (
                      <div className="main--event">
                        <Button className="main--btn" type="primary" size="large" onClick={this.setRedirect}>{this.state.data.eventView || "Watch event"}</Button>
                      </div> ): null
                    }
                    <div className="main--firts-section">
                        <div className="main--title">
                          <h1 className="main--title-h1"><b>{this.state.data.title}</b></h1>
                          <p className="main--title-p"><b>{this.state.data.content}</b></p>
                          <p className="main--title-p"><b>Digital Press Event</b></p>
                        </div>
                    </div>
                    <div className="main--dates">
                      <p><CalendarOutlined /> {eventRange}</p>
                    </div>
                    <div className="main--countdown">
                      { this.state.data.startDate ?
                        <Countdown
                          date={startDate.format('YYYY/MM/DD HH:mm:ss')}
                          useFlipView={true}
                          expiryMsg={""}
                        /> : null }
                    </div>
                    {!isMobile ? (
                      <div className="main--event">
                        {/*<Button className="main--btn" type="primary" size="large" onClick={this.setRedirect}>{this.state.data.eventView || "Watch event"}</Button>*/}
                        <Button className="main--btn" type="primary" size="large" href={this.state.data.eventLink}>{this.state.data.eventView || "Watch event"}</Button>
                      </div> ): null
                    }
                  </div>
                </Layout>
            </div>
        );
    }
}
