
import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import styles from './Cookies.scss';
import Core from '../Core';
import {
  Link
} from "react-router-dom";

class Cookies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accepted: sessionStorage.getItem('cookies-accepted')
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        sessionStorage.setItem('cookies-accepted', '1');
        this.setState({ accepted: '1' });
    }

    render() {
        if (this.state.accepted === '1') {
            return null;
        }
        return (
            <div className="cookies">
              <div className="cookies--popup">
                <div className="cookies--popup__content">
                  <p className="cookies--popup__text">We are using <Link to="/politica-de-cookies" onClick={ () => Core.menu.click('cookies-policies') }>Cookies</Link> to give you the best experience on our website.&nbsp;&nbsp;
                  <Button type="primary" shape="round" onClick={ this.onClick }>
                    Accept
                  </Button>
                  </p>
                </div>
              </div>
            </div>
        );
    }
}

export default Cookies;
