import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import Player from "../../containers/Player";
import Core from "../../Core";

import "./index.scss";

export default class PlayerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
        this.verifyLogin = this.verifyLogin.bind(this);
    }

    componentDidMount() {
        this.verifyLogin();
        this.props.hideMenu();
    }

    componentWillUnmount() {
        this.props.showMenu()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    verifyLogin() {
        if (!Core.data().login) {
            this.setState({ redirect: true });
        }
    }

    render() {
        const { redirect } = this.state;
        if (redirect) {
            if (Core.data().config.login == true) {
                return <Redirect to="/login" />;
            }
            return <Redirect to="/" />;
        }
        return (
            <div className="page">
                <Player />
            </div>
        );
    }
}
